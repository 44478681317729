<template>
  <modal v-model="showModal" dimmer maskClose hideClose :hideFooter="false" title="设置分组名称" @on-ok="onOk" @on-cancel="onCancel">
    <div class="group-add-item">
      <span>名称：</span>
      <y-input v-model="groupName"></y-input>
    </div>
  </modal>
</template>
<script>
import Modal from '../common/modal/modal'
import YInput from '../common/input/input'
export default {
  components: { Modal, YInput },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    value: String
  },
  data () {
    return {
      showModal: this.show,
      groupName: this.value || ''
    }
  },
  watch: {
    show (val) {
      this.showModal = val
    },
    showModal (val) {
      if (!val) {
        this.$emit('on-hide')
      }
    }
  },
  methods: {
    onOk () {
      if (this.groupName) {
        this.$emit('on-ok', this.groupName)
        this.showModal = false
      }
    },
    onCancel () {
      this.showModal = false
    }
  }
}
</script>
<style lang="less">
.group-add-item {
  padding: 15px;
  display: flex;
  align-items: center;
  input {
    width: 250px;
  }
}
</style>
