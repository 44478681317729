<template>
  <div class='paper-panel'>
    <div class='title'>
      <span class="group-name">{{group.name}}</span>
      <span class="delete" @click.stop="handleDeletePaper"><span class="fa fa-remove"></span>删除</span>
      <span class="transfer" @click.stop="handleTransferPaper"><span class="fa fa-random"></span>移动</span>
      <!-- <span class='more'>展开></span> -->
      <span class="more setting">
        <span class="fa fa-cog"></span>
        <ul>
          <li @click.stop="exportNoteExpress">导出NoteExpress格式</li>
          <li @click.stop="exportBibTex">导出BibTex格式</li>
          <li @click.stop="exportRIS">导出RIS格式</li>
          <!-- <li>导出Mendeley格式</li> -->
          <li v-if="group.id !== 'collect'" @click.stop="handleDeleteGroup">删除分组</li>
          <li v-if="group.id !== 'collect'" @click.stop="handleChangeGroupName">修改组名</li>
          <li @click.stop="paperRead">标记已读</li>
          <li @click.stop="handleShowAuthors">{{showAuthors?'隐藏作者':'显示作者'}}</li>
          <li @click.stop="handleShowSorts">{{showSorts?'隐藏自定义排序':'自定义排序'}}</li>
          <li @click.stop="handleSortsType">{{sortsTypeLabel}}</li>
        </ul>
      </span>
      <span class='more'>
        <router-link class="export-citation" target="_blank" :to="{name: 'citations', query: {id: this.group.id}}">导出引用格式</router-link>
      </span>
      <span class='more export' @click.stop="exportEndNote">导出EndNote格式</span>
      <span class="more expand fa"
        :class="[status==='expand'?'fa-minus-circle':'fa-plus-circle']"
        :title="status==='expand'?'折叠':'展开'"
        @click.stop="toggleShow"></span>
    </div>
    <ul class='head' v-show="status==='expand'">
      <li class='line-item'>
        <span class='index'><span><input type="checkbox" v-model="isSelectAll"/></span></span>
        <span class='index2'><span>序号</span></span>
        <span class='title'><span>标题</span></span>
        <span class='journal'><span>期刊</span></span>
        <span class='publication-date'><span>发表时间</span></span>
        <span class='if'><span>影响因子</span></span>
        <span class='citation'><span>被引次数</span></span>
      </li>
    </ul>
    <ul class='body' v-show="status==='expand'">
      <li class='line-item' v-for='(item, index) in paperList' :key='item.id'>
        <span class='index'><span><input type="checkbox" :value="item.id" v-model="selectedList"/></span></span>
        <span class='index2' :class="{read: item.read}"><span>{{index+1}}</span></span>
        <span class='title'>
          <router-link target="_blank" :to="{name: 'paper', params: {id: item.id}}">{{item.a && item.a.t}}</router-link>
        </span>
        <span class='journal'><span @mouseenter="handleJournalMouseEnter(item, $event)" @mouseleave="handleJournalMouseLeave()">{{getJournalTitle(item)}}</span></span>
        <span class='publication-date'><span>&nbsp;{{getPubDate(item)}}</span></span>
        <span class='if'><span>&nbsp;{{item.impactFactor}}</span></span>
        <span class='citation'><span>&nbsp;{{item.citedby}}</span></span>
        <div class="authors" v-show="showAuthors">{{getAuthors(item)}}</div>
        <div class="sort-up-down" v-show="showSorts">
          <span v-if="index>0" class="sort-icon fa fa-long-arrow-up" :class="{active: item.id===activeId && activeType==='up'}" @click.stop="sortUp(item, index)"></span>
          <span v-if="index<paperList.length-1" class="sort-icon fa fa-long-arrow-down" :class="{active: item.id===activeId && activeType==='down'}"  @click.stop="sortDown(item, index)"></span>
        </div>
      </li>
    </ul>
    <group-delete-win :show="showGroupDeleteWin" @on-hide="showGroupDeleteWin=false" @on-ok="deletePapers">确定删除文献？</group-delete-win>
    <div class="if-chart" v-show="showIfList" :style="chartPos">
      <div class="chart-box">
        <y-line :width="500" :height="200" :data="ifList" :chartTitle="chartTitle" :options="optionsIfList"></y-line>
      </div>
    </div>
    <div class="j-full-title" v-show="showJFullTitle" :style="chartPos">
      <div class="j-full-title-box">{{chartTitle}}</div>
    </div>
    <modal v-model="showDownload" dimmer>
      <div class="manual-close-win">
        <span class="text">{{'导出中，请等待...'}}</span>
      </div>
    </modal>
    <tooltip-win :show="showTipWin" @on-hide="showTipWin=false" :duration="500" text="更新排序成功" tipType="success"></tooltip-win>
    <group-add-win v-model="group.name" :show="showGroupNameWin" @on-hide="showGroupNameWin=false" @on-ok="onGroupUpdate"></group-add-win>
  </div>
</template>
<script>
import GroupDeleteWin from './group-delete-win'
import GroupAddWin from './group-add-win'
import TooltipWin from '../login-tip-win/tooltip-win'
import ChartMixin from '../paper-search/mixins/chart'
import Modal from '../common/modal/modal'
import YLine from '../common/charts/line'
import { getStorageItem } from '../../utils/cache'
import { authenticate, catchExpired } from '../../utils/auth'
export default {
  components: {GroupDeleteWin, GroupAddWin, TooltipWin, Modal, YLine},
  mixins: [ChartMixin],
  props: {
    group: {
      type: Object,
      default: function () { return {} }
    },
    initStatus: String
  },
  data () {
    return {
      paperList: [],
      isSelectAll: false,
      selectedList: [],

      showGroupDeleteWin: false,
      showDownload: false,
      showAuthors: false,
      showSorts: false,
      sortsType: 'normal',
      sortsTypeLabel: '时间降序显示',
      paperIdList: [],
      activeId: '',
      showTipWin: false,

      status: this.initStatus || 'collapse',
      showGroupNameWin: false
    }
  },
  watch: {
    isSelectAll (val) {
      if (val && this.selectedList.length < this.paperList.length) {
        this.selectedList = this.paperList.map(p => p.id)
      } else if (!val && this.selectedList.length === this.paperList.length) {
        this.selectedList = []
      }
    },
    selectedList (val) {
      if (val.length < this.paperList.length && this.isSelectAll) {
        this.isSelectAll = false
      } else if (val.length === this.paperList.length && !this.isSelectAll) {
        this.isSelectAll = true
      }
    }
  },
  mounted () {
    this.loadPaperList()
  },
  methods: {
    isLogin () {
      let auth = getStorageItem('auth')
      if (!auth) {
        this.$emit('on-login')
        return false
      }
      return true
    },
    loadPaperList () {
      if (this.group.id === 'collect') {
        this.loadCollectPaperList()
      } else {
        this.loadGroupPaperList()
      }
    },
    loadCollectPaperList () {
      this.$http.get(`${this.httpRoot}/paper/collect/list`, authenticate())
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success') {
          this.paperList = res.paperList || []
          // get call paper if list
          let pmidIssnList = []
          res.paperList.forEach(p => {
            pmidIssnList.push({pmid: p.id, issn: p.j ? p.j.issn || '' : ''})
          })
          this.getIfList(pmidIssnList, iflistMap => {
            this.paperList.forEach(paper => {
              if (iflistMap[paper.id] && iflistMap[paper.id].length) {
                paper.ifList = iflistMap[paper.id] || []
              }
            })
          })
        }
      })
      .catch(err => {
        catchExpired(err, this)
      })
    },
    loadGroupPaperList () {
      this.$http.get(`${this.httpRoot}/group/paper/list`, authenticate({params: {id: this.group.id}}))
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success') {
          this.paperList = res.paperList || []
          // get call paper if list
          let pmidIssnList = []
          res.paperList.forEach(p => {
            pmidIssnList.push({pmid: p.id, issn: p.j ? p.j.issn || '' : ''})
          })
          this.getIfList(pmidIssnList, iflistMap => {
            this.paperList.forEach(paper => {
              if (iflistMap[paper.id] && iflistMap[paper.id].length) {
                paper.ifList = iflistMap[paper.id] || []
              }
            })
          })
        }
      })
      .catch(err => {
        catchExpired(err, this)
      })
    },
    sortPaperList () {
      let path = 'group/paper/sort'
      if (this.group.id === 'collect') {
        path = 'paper/collect/sort'
      }
      let pmids = this.paperList.map(item => item.id)
      this.$http.post(`${this.httpRoot}/${path}`, {id: this.group.id, pmids}, authenticate())
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success') {
          this.showTipWin = true
        }
      })
      .catch(err => {
        catchExpired(err, this)
      })
    },
    handleJournalMouseEnter (paper, e) {
      let rect = {left: e.clientX, top: e.clientY}
      if (e.currentTarget) {
        let bdrect = e.currentTarget.getBoundingClientRect()
        rect = {left: bdrect.left, top: bdrect.top}
      }
      let journalFullTitle = ''
      if (paper.j && paper.j.t) {
        journalFullTitle = paper.j.t
        if (journalFullTitle.indexOf(':') > 0) {
          let ary = journalFullTitle.split(':')
          if (ary.length > 0) {
            journalFullTitle = ary[0].trim()
          }
        }
      }
      this.onJournalMouseEnter(paper.ifList, rect, journalFullTitle)
    },
    handleJournalMouseLeave () {
      this.onJournalMouseLeave()
    },
    handleDeletePaper () {
      if (!this.isLogin()) return
      if (!this.selectedList.length) {
        this.$emit('on-tooltip', {text: '请勾选组内的文献'})
        return
      }
      this.showGroupDeleteWin = true
    },
    handleTransferPaper () {
      if (!this.isLogin()) return
      if (!this.selectedList.length) {
        this.$emit('on-tooltip', {text: '请勾选组内的文献'})
        return
      }
      this.$emit('on-transfer', {groupId: this.group.id, pmids: this.selectedList})
    },
    handleDeleteGroup () {
      this.$emit('on-group-delete', this.group.id)
    },
    deletePapers () {
      let path = 'group/paper/delete'
      if (this.group.id === 'collect') {
        path = 'paper/uncollect'
      }
      this.$http.post(`${this.httpRoot}/${path}`, {id: this.group.id, pmids: this.selectedList}, authenticate())
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success') {
          this.loadPaperList()
        }
      })
      .catch(err => {
        catchExpired(err, this)
      })
    },
    handleChangeGroupName () {
      this.showGroupNameWin = true
    },
    onGroupUpdate (groupName) {
      this.$http.post(`${this.httpRoot}/group/rename`, {id: this.group.id, newName: groupName}, authenticate())
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success') {
          this.group.name = groupName
        }
      })
      .catch(err => {
        catchExpired(err, this)
      })
    },
    exportEndNote () {
      if (!this.isLogin()) return
      let pmids = this.paperList.map(p => p.id)
      if (this.selectedList.length) pmids = this.selectedList
      this.showDownload = true
      this.$http.post(`${this.httpRoot}/paper/endnote/export`, {
        pmids: pmids
      }, authenticate({responseType: 'blob'}))
      .then(res => res.blob())
      .then(blob => {
        var a = document.createElement('a')
        var url = window.URL.createObjectURL(blob)
        a.href = url
        a.download = 'endnote.' + new Date().getTime() + '.nbib'
        a.click()
        window.URL.revokeObjectURL(url)
        this.showDownload = false
      })
      .catch(err => {
        catchExpired(err, this)
      })
    },
    exportNoteExpress () {
      if (!this.isLogin()) return
      let pmids = this.paperList.map(p => p.id)
      if (this.selectedList.length) pmids = this.selectedList
      this.showDownload = true
      this.$http.post(`${this.httpRoot}/paper/noteexpress/export`, {
        pmids: pmids
      }, authenticate({responseType: 'blob'}))
      .then(res => res.blob())
      .then(blob => {
        var a = document.createElement('a')
        var url = window.URL.createObjectURL(blob)
        a.href = url
        a.download = 'noteexpress.' + new Date().getTime() + '.net'
        a.click()
        window.URL.revokeObjectURL(url)
        this.showDownload = false
      })
      .catch(err => {
        catchExpired(err, this)
      })
    },
    exportBibTex () {
      if (!this.isLogin()) return
      let pmids = this.paperList.map(p => p.id)
      if (this.selectedList.length) pmids = this.selectedList
      this.showDownload = true
      this.$http.post(`${this.httpRoot}/paper/bibtex/export`, {
        pmids: pmids
      }, authenticate({responseType: 'blob'}))
      .then(res => res.blob())
      .then(blob => {
        var a = document.createElement('a')
        var url = window.URL.createObjectURL(blob)
        a.href = url
        a.download = 'BibTex.' + new Date().getTime() + '.bib'
        a.click()
        window.URL.revokeObjectURL(url)
        this.showDownload = false
      })
      .catch(err => {
        catchExpired(err, this)
      })
    },
    exportRIS () {
      if (!this.isLogin()) return
      let pmids = this.paperList.map(p => p.id)
      if (this.selectedList.length) pmids = this.selectedList
      this.showDownload = true
      this.$http.post(`${this.httpRoot}/paper/ris/export`, {
        pmids: pmids
      }, authenticate({responseType: 'blob'}))
      .then(res => res.blob())
      .then(blob => {
        var a = document.createElement('a')
        var url = window.URL.createObjectURL(blob)
        a.href = url
        a.download = 'RIS.' + new Date().getTime() + '.ris'
        a.click()
        window.URL.revokeObjectURL(url)
        this.showDownload = false
      })
      .catch(err => {
        catchExpired(err, this)
      })
    },
    getJournalTitle (p) {
      let jtitle = ''
      if (p.j && p.j.abbr) jtitle = p.j.abbr
      if (p.b && p.b.t) jtitle = p.b.t
      if (p.j && p.j.t && !jtitle) jtitle = p.j.t
      let parts = jtitle.split('.')
      jtitle = parts.join('')
      jtitle = jtitle + '.'
      return jtitle
    },
    getPubDate (p) {
      let pubDate = ''
      if (p.j && p.j.date) pubDate = p.j.date
      if (p.b && p.b.date && p.b.date.y) pubDate = p.b.date
      if (typeof pubDate === 'object') {
        pubDate = (pubDate.y || '') + ' ' + (pubDate.m || '') + ' ' + (pubDate.d || '')
      }
      return pubDate
    },
    getAuthors (p) {
      let authorstring = ''
      if (p && p.aus) {
        p.aus.forEach((author, index) => {
          let a = author.fn + ' ' + author.ln
          if (index > 0) {
            authorstring += ', ' + a
          } else {
            authorstring += a
          }
        })
      }
      return authorstring
    },
    sortPubDate (list) {
      list.sort((a, b) => {
        let dateA = this.getPubDate(a)
        let dateB = this.getPubDate(b)
        if (dateA < dateB) {
          return 1
        }
        if (dateA > dateB) {
          return -1
        }
        return 0
      })
      return list
    },
    sortIf (list) {
      list.sort((a, b) => {
        let dateA = a.impactFactor || 0
        let dateB = b.impactFactor || 0
        if (dateA < dateB) {
          return 1
        }
        if (dateA > dateB) {
          return -1
        }
        return 0
      })
      return list
    },
    sortNormal () {
      let pList = []
      this.paperIdList.forEach(id => {
        this.paperList.forEach(paper => {
          if (id === paper.id) {
            pList.push(paper)
          }
        })
      })
      return pList
    },
    paperRead () {
      if (!this.selectedList.length) {
        this.$emit('on-tooltip', {text: '请勾选组内的文献'})
        return
      }
      this.read()
    },
    read () {
      this.$http.post(`${this.httpRoot}/paper/read`, {pmids: this.selectedList}, authenticate())
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success') {
          this.paperList.forEach(paper => {
            if (this.selectedList.includes(paper.id)) {
              paper.read = true
            }
          })
          this.$emit('on-tooltip', {text: '已标记', statusType: 'success'})
        }
      })
      .catch(err => {
        catchExpired(err, this)
      })
    },
    handleShowAuthors () {
      this.showAuthors = !this.showAuthors
    },
    handleShowSorts () {
      this.showSorts = !this.showSorts
    },
    sortUp (item, index) {
      if (index > 0) {
        this.activeId = item.id
        this.activeType = 'up'
        let temps = this.paperList.splice(index - 1, 1)
        if (temps && temps.length) {
          this.paperList.splice(index, 0, temps[0])
          this.sortPaperList()
        }
      }
    },
    sortDown (item, index) {
      if (index < this.paperList.length - 1) {
        this.activeId = item.id
        this.activeType = 'down'
        let temps = this.paperList.splice(index, 1)
        if (temps && temps.length) {
          this.paperList.splice(index + 1, 0, temps[0])
          this.sortPaperList()
        }
      }
    },
    handleSortsType () {
      // 收藏次序排序
      if (this.sortsType === 'normal') {
        // 时间排序
        this.paperIdList = this.paperList.map(item => item.id)
        this.sortsType = 'pubDate'
        this.paperList = this.sortPubDate(this.paperList)
        // 下次按IF排
        this.sortsTypeLabel = 'IF值降序显示'
      } else if (this.sortsType === 'pubDate') {
        // IF值排序
        this.sortsType = 'impactFactor'
        this.paperList = this.sortIf(this.paperList)
        // 下次按收藏次序排序
        this.sortsTypeLabel = '默认次序显示'
      } else if (this.sortsType === 'impactFactor') {
        // 收藏次序排序
        this.sortsType = 'normal'
        this.paperList = this.sortNormal()
        // 下次按时间排序
        this.sortsTypeLabel = '时间降序显示'
      }
    },
    toggleShow () {
      if (this.status === 'expand') {
        this.status = 'collapse'
      } else {
        this.status = 'expand'
      }
    }
  }
}
</script>

<style lang="less">
.manual-close-win {
  height: 60px;
  line-height: 60px;
  padding-left: 60px;
  padding-right: 60px;
  background-color: #fff;
  border-radius: 4px;
  color: rgb(233, 71, 45);
}
</style>
