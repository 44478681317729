<template>
  <modal v-model="showModal" dimmer maskClose hideClose :hideFooter="false" title="选择转移目标分组" @on-ok="onOk" @on-cancel="onCancel">
    <div class="group-list">
      <div class="group" v-for="group in groups" :key="group.id">
        <input type="radio" :value="group.id" v-model="toGroupId" :disabled="group.id===originalGroupId">
        <span>{{group.name}}</span>
      </div>
    </div>
  </modal>
</template>
<script>
import Modal from '../common/modal/modal'
export default {
  components: { Modal },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    groups: {
      type: Array,
      default: function () { return [] }
    },
    originalGroupId: String
  },
  data () {
    return {
      showModal: this.show,
      toGroupId: ''
    }
  },
  watch: {
    show (val) {
      this.showModal = val
    },
    showModal (val) {
      if (!val) {
        this.$emit('on-hide')
      }
    }
  },
  methods: {
    onOk () {
      if (this.toGroupId && this.toGroupId !== this.originalGroupId) {
        this.$emit('on-trans-paper', this.toGroupId)
        this.showModal = false
      }
    },
    onCancel () {
      this.showModal = false
    }
  }
}
</script>
<style lang="less">
.group-list {
  padding: 0 15px;
  .group {
    min-width: 150px;
  }
}
</style>
