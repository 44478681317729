<template>
  <div class="bg-gray">
    <div class='paper-manage page-box'>
      <div class='head-box'>
        <!-- <div class='block'>试验文献精选</div>
        <div class='block'>Top100</div>
        <div class='block'>其它...</div> -->
        <div class='block' @click.stop="handleGroupAdd">+添加分组</div>
      </div>
      <div class='page-box'>
        <group-panel ref="collectPanel" :group="groupCollect" @on-tooltip="handleTooltip" @on-login="onLogin" @on-transfer="handleTransfer" initStatus='expand'></group-panel>
        <group-panel ref="groupPanels" v-for="(item, index) in groupList" :group="item" :key="index" :class="{last: index===groupList.length-1}"
          @on-tooltip="handleTooltip" @on-login="onLogin" @on-transfer="handleTransfer" @on-group-delete="onGroupDelete"></group-panel>
      </div>
    </div>
    <tooltip-win :show="showTipWin" @on-hide="showTipWin=false" :text="tipText" :tipType="statusType"></tooltip-win>
    <login-tip-win2 :show="showLoginTipWin2" @on-hide="showLoginTipWin2=false"></login-tip-win2>
    <group-trans-win :show="showGroupTransWin" @on-hide="showGroupTransWin=false"
      :groups="groupList"
      :originalGroupId="originalGroupId"
      @on-trans-paper="onTransPaper"></group-trans-win>
    <group-add-win :show="showGroupAddWin" @on-hide="showGroupAddWin=false" @on-ok="onGroupAdd"></group-add-win>
    <group-delete-win :show="showGroupDeleteWin" @on-hide="showGroupDeleteWin=false" @on-ok="deleteGroup">确定删除该分组？</group-delete-win>
  </div>
</template>
<script>
import GroupPanel from './group-panel'
import TooltipWin from '../login-tip-win/tooltip-win'
import LoginTipWin2 from '../login-tip-win/login-tip-win2'
import GroupTransWin from './group-trans-win'
import GroupAddWin from './group-add-win'
import GroupDeleteWin from './group-delete-win'
import Login from '../../utils/login'
import { authenticate, catchExpired } from '../../utils/auth'
import { updateStorageItem } from '../../utils/cache'

export default {
  components: {GroupPanel, GroupTransWin, GroupAddWin, GroupDeleteWin, TooltipWin, LoginTipWin2},
  mixins: [Login],
  data () {
    return {
      groupCollect: {
        id: 'collect',
        name: '我的收藏'
      },
      groupList: [],

      showTipWin: false,
      tipText: '',
      statusType: '',

      originalGroupId: '',
      originalPmids: [],

      showGroupTransWin: false,
      showGroupAddWin: false,
      showGroupDeleteWin: false,

      showLoginTipWin2: false
    }
  },
  mounted () {
    document.title = '在线文献管理'
    this.loadGroupList()
  },
  methods: {
    loadGroupList () {
      this.$http.get(`${this.httpRoot}/group/list`, authenticate())
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success') {
          this.groupList = res.groupList || []
        }
      })
      .catch(err => {
        catchExpired(err, this)
      })
    },
    addPapers (groupId, pmids) {
      this.$http.post(`${this.httpRoot}/group/paper/add`, {id: groupId, pmids}, authenticate())
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success') {
          this.$refs.groupPanels.forEach(groupPanel => {
            if (groupPanel.group.id === groupId) {
              groupPanel.loadPaperList()
            }
          })
        }
      })
      .catch(err => {
        catchExpired(err, this)
      })
    },
    deletePapers (groupId, pmids) {
      let path = 'group/paper/delete'
      if (groupId === 'collect') {
        path = 'paper/uncollect'
      }
      this.$http.post(`${this.httpRoot}/${path}`, {id: groupId, pmids}, authenticate())
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success') {
          if (groupId === 'collect') {
            this.$refs.collectPanel.loadPaperList()
          } else {
            this.$refs.groupPanels.forEach(groupPanel => {
              if (groupPanel.group.id === groupId) {
                groupPanel.loadPaperList()
              }
            })
          }
        }
      })
      .catch(err => {
        catchExpired(err, this)
      })
    },
    handleTooltip ({text, statusType}) {
      this.tipText = text
      this.statusType = statusType || ''
      this.showTipWin = true
    },
    handleTransfer ({groupId, pmids}) {
      this.originalGroupId = groupId
      this.originalPmids = pmids
      this.showGroupTransWin = true
    },
    onTransPaper (toGroupId) {
      this.addPapers(toGroupId, this.originalPmids)
      this.deletePapers(this.originalGroupId, this.originalPmids)
    },
    handleGroupAdd () {
      this.showGroupAddWin = true
    },
    onGroupAdd (groupName) {
      this.$http.post(`${this.httpRoot}/group/add`, {group: {name: groupName}}, authenticate())
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success') {
          this.loadGroupList()
        }
      })
      .catch(err => {
        catchExpired(err, this)
      })
    },
    onGroupDelete (groupId) {
      this.originalGroupId = groupId
      this.showGroupDeleteWin = true
    },
    deleteGroup () {
      this.$http.post(`${this.httpRoot}/group/delete`, {id: this.originalGroupId}, authenticate())
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success') {
          this.loadGroupList()
        }
      })
      .catch(err => {
        catchExpired(err, this)
      })
    },
    onLogin () {
      updateStorageItem('route', {name: 'manage'})
      this.showLoginTipWin2 = true
    }
  }
}
</script>
<style lang='less'>
.bg-gray {
  background: #f0f0f0;
  padding-bottom: 1px;
}
.paper-manage {
  font-size: 0.875rem;
}
.head-box {
  display: flex;
  .block {
    margin-top: 10px;
    background-color: #7ba0e4;
    color: #fff;
    width: 120px;
    height: 30px;
    line-height: 30px;
    cursor: pointer;
    text-align: center;
  }
}
.paper-panel {
  margin-top: 10px;
  margin-bottom: 20px;
  // max-height: 400px;
  // overflow: auto;
  background-color: #fff;
  &.last {
    margin-bottom: 150px;
  }
  &> .title {
    background-color: #ffff;
    font-size: 13px;
    height: 36px;
    line-height: 36px;
    padding-left: 10px;
    border-bottom: 1px solid #ddd;
    position: relative;
    .group-name {
      margin-right: 10px;
    }
    .more {
      padding-left: 15px;
      cursor: pointer;
      float: right;
      color: #3f5d94;
      a {
        color: #3f5d94;
        &.export-citation {
          &:hover {
            color: #278ef5;
          }
        }
      }
      &.export {
        &:hover {
          color: #278ef5;
        }
      }
      &.setting {
        position: relative;
        padding-right: 15px;
        &:hover {
          color: #278ef5;
          ul {
            display: block;
          }
        }
        ul {
          display: none;
          position: absolute;
          z-index: 9;
          color: #232323;
          top: 36px;
          right: 0;
          background: #fff;
          box-shadow: 0 1px 3px rgba(60,60,60,0.35);
          &>li {
            list-style: none;
            height: 32px;
            line-height: 32px;
            white-space: nowrap;
            padding: 0 15px;
            &:hover {
              background: #eee;
              color: #278ef5;
            }
          }
        }
      }
      &.expand {
        position: absolute;
        top: 0;
        left: -41px;
        height: 36px;
        line-height: 36px;
        padding-right: 15px;
        &:hover {
          color: #278ef5;
          background-color: #ddd;
        }
      }
    }
    .delete, .transfer {
      cursor: pointer;
      &:hover {
        color: #278ef5;
      }
      &> .fa {
        margin: 0 2px;
      }
    }
    .add {
      position: relative;
      margin-left: 0.5rem;
      cursor: pointer;
      &:before {
        content: ' ';
        position: absolute;
        top: 50%;
        left: 0;
        width: 14px;
        height: 1px;
        background: rgb(0, 54, 204);
      }
      &:after {
        content: ' ';
        position: absolute;
        top: 0;
        left: 6px;
        width: 1.5px;
        height: 14px;
        background: rgb(0, 54, 204);
      }
    }
  }
  .head {
    background-color: #e9eaef;
    font-size: 12px;
    color: #555;
  }
  ul {
    list-style: none;
    &.body {
      &> .line-item {
        &:hover {
          background-color: #cfcfcf;
          .title {
            color: rgb(173, 20, 20);
            cursor: pointer;
          }
        }
      }
    }
  }
  .line-item {
    padding: 5px 0;
    font-size: 13px;
    position: relative;
    &:nth-child(2n){
      background:#fafafa;
    }
    &> .index, &> .title, &> .journal, &> .publication-date, &> .if, &> .citation {
      display: inline-block;
      // white-space: nowrap;
      overflow: hidden;
      padding: 0 7px;
      box-sizing: border-box;
    }
    &> .index {
      width: 3%;
      float: left;
      text-align: end;
      input {
        cursor: pointer;
        vertical-align: middle;
      }
    }
    &> .index2 {
      width: 4%;
      float: left;
      text-align: center;
      &.read {
        color: #ff6112;
      }
    }
    &> .title {
      width: 53%;
      float: left;
      a {
        color: #3f5d94;
      }
    }
    &> .journal {
      width: 15%;
      float: left;
      white-space: nowrap;
      overflow: hidden;
    }
    &> .publication-date {
      width: 10%;
      float: left;
    }
    &> .if {
      width: 8%;
      float: left;
    }
    &> .citation {
      width: 7%;
    }
    &> .authors {
      font-size: 12px;
      color: #666;
      padding-left: 8%;
    }
    &:after {
      content: '.';
      display: block;
      height: 0;
      visibility: hidden;
      clear: both;
    }
    .sort-up-down {
      position: absolute;
      z-index: 6;
      width: 50px;
      right: -55px;
      top: 50%;
      transform: translateY(-50%);
    }
    .sort-icon {
      cursor: pointer;
      padding: 0 7px;
      &:hover {
        color: #3366ff;
      }
      &.active {
        background: #ff5f5f;
        border-radius: 50%;
        padding-top: 3px;
        padding-bottom: 3px;
      }
    }
  }
}
</style>
